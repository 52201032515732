
.logo {
    object-fit: cover;
    min-width: 1em;
    max-width: 1em;
    position: relative;
    grid-column: span 2
  }
  
  .logo::before {
    /* for apsect ratio */
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  
  .logo img {
    position: absolute;
    max-height: 68%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: grayscale(1);
  }

  .logo img.opacity {
      opacity: .7;
  }

.logo:nth-child(1) {
    grid-column: 4 / span 2;
}
  
.logo:nth-child(4) {
    grid-column: 1 / span 2;
}
  
.logo:nth-child(10) {
    grid-column: 4 / span 2;
}